export function match(param) {
	// TODO: (EVTL) Get these from database
	return [
		'home',
		'regierung',
		'wirtschaft',
		'medien',
		'religion',
		'familie',
		'bildung',
		'kultur',
		'israel',
	].includes(param);
}
